/**
 * @generated SignedSource<<e00d16c27aa75c85b85b2d2720e764d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ecommerceTracking_item$data = {
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ecommerceTrackingProductData_item">;
  readonly " $fragmentType": "ecommerceTracking_item";
};
export type ecommerceTracking_item$key = {
  readonly " $data"?: ecommerceTracking_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"ecommerceTracking_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ecommerceTracking_item"
};

(node as any).hash = "e31dff9a0c853fcde14a50db4b9c46d6";

export default node;
